<template>
  <div>
    <div class="favorites">
      <div class="favorites__ttl">
        <div class="favorites__back" @click="$router.go(-1)">
          <svg @click="$router.go(-1)" width="52" height="54" viewBox="0 0 52 54" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3281 24.5001L51.5 24.5001L51.5 29.5001L9.83153 29.5001L30.5677 50.2363L27.0322 53.7719L2.27652 29.0162L0.508753 27.2484L2.27652 25.4806L27.0322 0.724953L30.5677 4.26049L10.3281 24.5001Z"
                  fill="black"/>
          </svg>
        </div>
        <h1> {{ $fn.tr('Favorites') }}</h1>
      </div>
      <div class="favorites__subTtl">
        {{ $fn.tr('Do you need help choosing?') }}
        <span @click="openContractSales(true)">{{ $fn.tr('Contact Sales') }}</span>

      </div>
      <div class="container">
        <div class="favorites_items">
          <div class="favorites_item" v-for="(item, index) in favoriteItems" :key="index">
            <router-link
                :to="`/projects/cityzen-central-park/apview/block-${item.block.slug}/floor-${item.floor_number}/${item.id}-apartment-${item.flat_number}`"
                class="favorites_item-link"></router-link>
            <div class="favorites_item-img-out">
              <div class="favorites_item-img">
                <img :src="item.bluePrintImg"
                     :alt="$get(item,'title')" width="270" height="270"/>
              </div>
            </div>
            <div class="favorites_item-descr">
              <h3>{{ $fn.tr('Apartement') }} N{{ item.flat_number }}</h3>
              <div>
                <span>$ {{ item.price }}</span>
                <span>{{ item.development_types_area_m2 }} m<sup>2</sup></span>
              </div>
            </div>
            <div class="favorites_item-links">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.1724 5.59177L2.12132 24.6429L0 22.5215L19.3451 3.17646H0.0607356V0.176458H22.6724H24.1724V1.67646V24.2881H21.1724V5.59177Z"
                      fill="black"/>
              </svg>
              <div @click="removeToFavorites(item.id)"> {{ $fn.tr('Remove') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="favorites__subTtl">
        {{ $fn.tr('Want to share with friends?') }}
        <span @click="genLink">{{ $fn.tr('Generate Link') }}<div
            :class="copied ? 'active' : ''">{{ $fn.tr('Copied') }}</div></span>
      </div>
      <div class="noitem" v-if="favoriteItems.lenght === 0">
        <h2>{{ $fn.tr('FAVOURITES LIST IS EMPTY!') }}</h2>
        <router-link
            :to="'/' + storeMenu.indx.locale + getWidget.link.url"
            :target="getWidget.link.targetblank ? '_blank' : ''">{{ $fn.tr('EXPLORE CITYZEN') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {UseFavoriteFlats} from '@/store/pinia/FavoriteProjects';
import {useProjects} from "@/store/pinia/projects";
import get from "lodash/get";
import fn from "../../../helpers/func";
import {useMenuStore} from '@/store/pinia/menu';
import {useRouter, useRoute} from "vue-router";

let store = UseFavoriteFlats()
const storeMenu = useMenuStore();
let router = useRouter()
let route = useRoute()
let projectStore = useProjects();
let copied = ref(false);

const getWidget = computed(() => {
  return get(storeMenu, 'indx.widgets.explore-cityzen');
});
let removeToFavorites = (id) => {
  store.addFavorite(id);
}
let openContractSales = (val) => {
  store.setContactSale(val)
}
const types = computed(() => projectStore._types);

let allFlats = computed(() => store.flats.list)
let favoriteFlatIds = computed(() => route.query.flats ? route.query.flats.split(',').map(Number) : store.favoriteFlatIds)
// let favoriteFlatIds = computed(() => store.favoriteFlatIds)

const favoriteItems = computed(() => {

  return allFlats.value?.filter(item => favoriteFlatIds.value?.includes(item.id))?.map(item => {
    let type = types.value?.find(type => {
      return type.id === item?.type_id
    }) || {}
    return {
      ...item,
      type: type,
      bluePrintImg: type?.image2d?.[0]?.devices?.desktop,
      image3d: type?.image3d?.[0]?.devices?.desktop,
      viewImg: type?.imageview?.[0]?.devices?.desktop,
      block: projectStore._blocks?.find(block => block.id === item.block_id) || {}
    }
  }) || []
})

const genLink = () => {
  copied.value = true;
  let txt = window.location.href;
  navigator.clipboard.writeText(txt)
  setTimeout(() => {
    copied.value = false;
  }, 1500)
}
onMounted(async () => {
  store.getFavoriteFlats()
  projectStore.getTypes()
  projectStore.getBlocks()

  // setTimeout(() => {
  //   let routePath = route.path?.split('/')
  //
  //   router.push({
  //     path: `${routePath.join('?234')}`,
  //     query: route.query
  //   })
  //   console.log(routePath)
  //   console.log(routePath)
  // },1000)
})

</script>

<style lang="scss" scoped>
.noitem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.noitem h2 {
  font-size: 74px;
  line-height: 89px;
}

.noitem a {
  font-size: 52px;
  line-height: 63px;
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  border-bottom: 1px solid #000;
  margin-top: 50px;
}

.noitem a:hover {
  border-color: rgba(255, 255, 255, 0);
}

.favorites_item-links svg {
  cursor: pointer;
}

.favorites_item-link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.favorites__ttl {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
  min-height: 27px;
}

.favorites__ttl h1 {
  font-size: 74px;
  line-height: 85px;
  text-transform: uppercase;
}

.favorites__back {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  left: 36px;
  top: 0px
}

.favorites__back svg {
  cursor: pointer;
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
}

.favorites__back svg:hover {
  transform: translateX(-10px);
}

.favorites__subTtl {
  display: flex;
  justify-content: center;
  font-size: 34px;
  line-height: 40px;
  padding-bottom: 35px;
}

.favorites__subTtl div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100%;
  right: 0px;
  font-size: inherit;
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  opacity: 0;
  pointer-events: none;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorites__subTtl div.active {
  opacity: 1;
}

.favorites__subTtl span {
  font-size: inherit;
  line-height: inherit;
  margin-left: 10px;
  border-bottom: 1px solid #000;
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  cursor: pointer;
  position: relative;
}

.favorites__subTtl span:hover {
  border-color: rgba(0, 0, 0, 0);
}

.favorites_item {
  display: flex;
  width: 100%;
  background: rgba(241, 241, 241, 1);
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  padding: 20px;
  margin-bottom: 12px;
  position: relative;
}

.favorites_item:hover {
  background: rgba(241, 241, 241, .6);
}

.favorites_item-img-out {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.favorites_item-img img {
  width: 220px;
  height: 210px;
  object-fit: contain;
}

.favorites_item-descr {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 60px;
}

.favorites_item-descr h3 {
  font-size: 48px;
  line-height: 56px;
}

.favorites_item-descr div span {
  font-size: 32px;
  margin-right: 50px;
}

.favorites_items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 110px;
  margin-bottom: 55px;
}

.favorites_item-links {
  margin-left: auto;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  z-index: 1;
}

.favorites_item-links svg {
  margin-left: auto;
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
}

.favorites_item:hover .favorites_item-links svg {
  transform: translateX(5px) translateY(-5px);
}

.favorites_item-links div {
  border-bottom: 1px solid #000;
  transition: 0.6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  cursor: pointer;
}

.favorites_item-links div:hover {
  border-color: rgba(0, 0, 0, 0);
}

//Laptop
@media (max-width: 1900px) {
  .noitem h2 {
    font-size: 45px;
    line-height: 69px;
  }
  .noitem a {
    font-size: 32px;
    line-height: 43px;
    margin-top: 30px;
  }
  .favorites__ttl h1 {
    font-size: 48px;
    line-height: 56px;
  }
  .favorites__back svg {
    height: 25px;
    width: 25px;
  }
  .favorites__ttl {
    margin-bottom: 20px;
  }
  .favorites__subTtl {
    font-size: 24px;
    line-height: 28px;
  }
  .favorites_items {
    margin-top: 60px;
  }
  .favorites_item-img-out {
    width: 250px;
  }
  .favorites_item-img img {
    width: 165px;
    height: 160px;
  }
  .favorites_item-descr h3 {
    font-size: 34px;
    line-height: 40px;
  }
  .favorites_item-descr {
    padding: 15px 35px;
  }
  .favorites_item-descr div span {
    font-size: 24px;
    margin-right: 35px;
  }
}

//Ipad
@media (max-width: 1279px) {
  .favorites_item-links div {
    font-size: 12px;
    line-height: 12px;
  }
  .favorites_item-links svg {
    height: 9px;
    width: 9px;
  }
  .favorites_item {
    padding: 10px;
  }
  .favorites__ttl h1 {
    font-size: 24px;
    line-height: 28px;
  }
  .favorites__subTtl {
    font-size: 16px;
    line-height: 20px;
  }
  .favorites_item-img-out {
    width: 115px;
    padding: 5px;
  }
  .favorites_item-img img {
    width: 90px;
    height: 85px;
    margin-right: 15px;
  }
  .favorites_item-descr h3 {
    font-size: 16px;
    line-height: 18px;
  }
  .favorites_item-descr div span {
    font-size: 12px;
    margin-right: 18px;
  }
}

//Mobile
@media (max-width: 767px) {
  .noitem a {
    font-size: 22px;
    line-height: 33px;
    margin-top: 20px;
  }
  .noitem h2 {
    font-size: 35px;
    line-height: 49px;
  }
  .noitem {
    height: 50vh;
  }
  .favorites__back {
    left: 21px;
    display: none;
  }
  .favorites__back svg {
    height: 16px;
    width: 16px;
  }
  .favorites__subTtl {
    padding-left: 21px;
    flex-direction: column;
    align-items: flex-start;
  }
  .favorites__subTtl span {
    margin-left: 0px;
    margin-top: 15px;
  }
  .favorites_item-img img {
    width: 170px;
    height: 170px;
    margin-right: 0px;
  }
  .favorites_item-img-out {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 170px;
    border-right: 0px;
  }
  .favorites_item {
    position: relative;
    height: 300px;
    padding: 20px;
    flex-direction: column;
  }
  .favorites_item-descr {
    padding: 0px;
    height: 100%;
    width: calc(100% - 170px);
  }
  .favorites_item-descr div {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
  .favorites_item-descr div span {
    font-size: 18px;
    margin-right: 22px;
    margin-top: 5px;
  }
  .favorites_item-links {
    margin-left: 0px;
  }
  .favorites_item-links svg {
    display: none;
  }
  .favorites_item-links div {
    margin-right: auto;
    margin-top: 20px;
  }
  .favorites_items {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
</style>
